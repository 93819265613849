import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // 样式
import Cache from '@/common/cache'
import Lodash from 'lodash'
import router from './routers'
import Cookies from 'js-cookie'
import Store from '@/store'
import Axios from "@/api/axios.js"
import Api from "@/api/index.js"
import menu from "@/router/menu.js"
import {
  verifyAuth
} from '@/common/jwt'
import {
  filterAsyncRouter
} from '@/store/modules/permission'
const noNeedLogin = ["/login", "/version/list"]

NProgress.configure({
  showSpinner: false
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + process.env.VUE_APP_TITLE;
  }
  NProgress.start();
  //判断是否登录
  if (!Lodash.isEmpty(Cookies.get(Cache.TOKEN))) {
    //已登录如果是去登陆页直接去首页
    if (to.path === '/login') {
      next({
        path: '/'
      })
    } else {
      if (Store.getters.addRouters.length === 0) {
        loadMenus(next, to)
      } else {
        isGrainDepot(to, next);
      }

    }
  } else {
    if (!noNeedLogin.includes(to.path)) {
      next({
        path: '/login',
        replace: true
      })
    } else {
      next()
    }
  }
})

//过滤菜单
function filterMenu(list, m = []) {
  list.forEach(item => {
    if (item.children && item.children.length > 0) {
      filterMenu(item.children, m)
    } else {
      m.push(item)
    }
  })

  return m;
}


export const loadMenus = (next, to) => {
  Axios.Get(Api.menuLeft, {}).then(function(res) {
    const asyncRouter = [...menu.leftMenu, ...filterAsyncRouter(res.data)]
    Store.dispatch('GenerateRoutes', asyncRouter).then(() => { // 存储路由
      router.addRoutes([{
        path: '/',
        meta: {
          title: 'index'
        },
        component: (resolve) => require(['@/views/index'], resolve),
        redirect: "/" + process.env.VUE_APP_SYSTEM_HOME,
        children: filterMenu(asyncRouter)
      }]) // 动态添加可访问路由表
      next({
        ...to,
        replace: true
      })
    })
  })
}


//判断是否有粮库
function isGrainDepot(to, next) {
  if (Cookies.get(Cache.LIANG_ID) || to.path === '/grain/change' || !verifyAuth(Cookies.get(Cache.TOKEN),
      'liang_change')) {
    next()
  } else {
    next({
      path: '/grain/change',
      replace: true
    })
  }
}

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
