const permission = {
  state: {
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
    }
  },
  actions: {
    GenerateRoutes({
      commit
    }, asyncRouter) {
      commit('SET_ROUTERS', asyncRouter)
    }
  }
}

export const filterAsyncRouter = (routers) => { // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(router => {
    router.component =(resolve) => require([`@/views${router.menuPath}`], resolve)
    router.path = router.menuUrl
    router.name = router.menuName
    router.hide = !router.menuState
    router.meta = {
      title: router.menuName,
      noCache: !router.menuCache,
      icon: router.mennIcon,
      isBreadcrumb: true
    }
    if (router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children)
    }
    return true
  })
}

export const loadView = (view) => {
  return (resolve) => require([`@/views${view}`], resolve)
}

export default permission
