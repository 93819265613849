import Axios from "../../api/axios.js"
import Api from "../../api/index.js"
import Cookies from 'js-cookie'
import Cache from '@/common/cache'

const user = {
  state: {
  
  },

  mutations: {
   
  },

  actions: {
    // 登出
    LogOut() {
      return new Promise((resolve) => {
        Axios.Post(Api.UserCancellation, {})
        Cookies.remove(Cache.TOKEN)
        Cookies.remove(Cache.LIANG_ID)
        resolve()
      })
    },
  }
}

export default user
