//请求地址
module.exports = {
  BaseUrl: process.env.VUE_APP_BASE_API,
  // BaseUrl: '/api/',
  LoginAuth: "login/auth", //登录

  testingTempPage: 'testing-temp/list-pages',
  testingTempDel: 'testing-temp/del',
  testingTempExcle: 'testing-temp/excle-cangType/',
  testingTempExcleSumman: 'testing-temp/excle-summany',
  testingTempByIdTable: 'testing-temp/by-id-table/',
  byAbarnTestingTime: 'testing-temp/by-abarn-testing-time/',
  testingTempExcleId: "testing-temp/excle-id/",
  byIdTableHighOrCircle: "testing-temp/by-id-table-high-or-circle",
  byAbarnTimeSpot: "testing-temp/by-abarn-time-spot-g2",
  byAbarnTime: "testing-temp/by-abarn-time-g2",
  byIdAbarn: "testing-temp/by-id-abarn/",
  solidByIdAbarnHigh: "testing-temp/by-id-testing-high",
  solidByIdAbarnRow: "testing-temp/by-id-testing-row",
  printCangType: 'testing-temp/see-print-cangType/',
  summanyData: 'testing-temp/summany-data',

  getArchivesPage: 'cf-abarn-archives/list-page',
  getNotArchivesHouse: "cf-abarn-archives/not-archives-house",

  cfCangTypeList: "cf-cang-type/list", //获取仓类型
  cfCangTypeModify: "cf-cang-type/modify", //仓类型修改

  cfMmappingSpotDel: "cf-mapping-spot/del",
  cfMmappingSpotSee: "cf-mapping-spot/see",
  cfMmappingSpotAdd: "cf-mapping-spot/add",

  cfExtensionList: "cf-extension/list", //无线路由
  cfExtensionAdd: "cf-extension/add",
  cfExtensionDel: "cf-extension/del",
  cfExtensionModify: "cf-extension/modify",

  UserCancellation: "sys-user/user-cancellation", //退出
  userListPage: "sys-user/list-page/",
  userEnable: "sys-user/enable",
  userRemove: "sys-user/remove",
  userModifyRole: "sys-user/modify-role",
  userAdd: "sys-user/add",
  userModify: "sys-user/modify-info",
  userName: "sys-user/list-name",
  userModifyPwd: "sys-user/modify-pwd",
  seeUserOwn: "sys-user/see-user-own",
  modifyUserOwn: "sys-user/modify-info-own",
  userListPageAll: "sys-user/list-page-all/",
  userRestPwd: "sys-user/reset-pwd",

  companyListPage: "cf-company/list-page",
  companyDel: "cf-company/del",
  companyEnable: "cf-company/enable",
  companyAdd: "cf-company/add",
  companyModify: "cf-company/modify",
  companyList: "cf-company/list-all",
  companyListByName: "cf-company/by-name",

  menuLeft: "sys-menu/left-menu",
  menuListPage: "sys-menu/menu-list-page",
  menuDel: "sys-menu/del-menu",
  menuAdd: "sys-menu/add-menu",
  menuEdit: "sys-menu/edit-menu",
  menuModifyLiang: "sys-menu/modify-menu-liang",
  menuModifyState: "sys-menu/modify-menu-state",
  menuModify: "sys-menu/modify-menu-admin",
  menuTreeAll: "sys-menu/menu-tree-all",

  roleListPage: "sys-role/role-list-page",
  roleDel: "sys-role/del-role",
  roleAuthAssign: "sys-role/auth-assign",
  roleList: "sys-role/list-role",
  roleAdd: "sys-role/add-role",
  roleEdit: "sys-role/edit-role",
  roleTreeAll: "sys-role/role-tree-all",
  roleTree: "sys-role/role-tree-current",

  grainLiangListPage: "cf-grain-depot/list-page",
  grainLiangDel: "cf-grain-depot/del",
  grainLiangAdd: "cf-grain-depot/add",
  grainLiangModify: "cf-grain-depot/modify",
  grainLiangRenew: "cf-grain-depot/renew",
  depotRenewRecord: "cf-grain-depot/list-page-renew-record",
  configGrain: "cf-grain-depot/by-id-name", //获取粮库配置

  grainLiangsNot: "sys-user-grain-depot/find-user-liangs-not",
  grainLiangs: "sys-user-grain-depot/find-user-liangs",
  grainAdd: "sys-user-grain-depot/add",
  grainRemove: "sys-user-grain-depot/remove",
  FindLiangs: 'sys-user-grain-depot/find-liangs', //获取粮库信息


  archivesExport: "cf-abarn-archives/export-data",
  archivesAdd: "cf-abarn-archives/add",
  archivesDel: "cf-abarn-archives/del",
  archivesModify: "cf-abarn-archives/modify",

  configureActivation: "sys-configure/activation",
  configurePath: "sys-configure/edition-path",
  configureDownPrefix: "sys-configure/down-prefix",
  configureCangType: "sys-configure/cang-config",
  configureAll: "sys-configure/configure-all",
  configureTestingConfig: "sys-configure/testing-config",


  editionList: "admin-edition/list",
  editionDel: "admin-edition/del",
  editionUpload: "admin-edition/upload-jar",
  editionRelease: "admin-edition/release",
  versionList: "admin-edition/version-list",

  LastTesting: 'cf-abarn/last-testing',
  byCangtypeLiangLocaidNo: "cf-abarn/by-cangtype-liang-locaid-no", //根据仓类型获取仓房信息
  getAbarnAll: 'cf-abarn/get-all',
  abarnNames: 'cf-abarn/by-select-names',
  cfAbarnStartCode: "cf-abarn/get-start-code",
  cfAbarnStartCodeNe: "cf-abarn/get-start-code-ne",
  cfAbarnCodeSize: "cf-abarn/get-code-size",
  cfAbarnGetMains: "cf-abarn/get-mains",
  cfAbarnGetAdd: "cf-abarn/add-abarn",
  cfAbarnGetDel: "cf-abarn/del-abarn",
  cfAbarnGetModify: "cf-abarn/modify-abarn",

  cfParamUpdate: "cf-param/update",
  cfParamSee: "cf-param/see",

  testingLuyou: "testing/testing-luyou",
  testingLuyouAll: "testing/testing-luyou-all",
  testingTemp:"testing/testing-temp"
}
