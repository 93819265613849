"use strict";
import Axios from "axios";
import Qs from 'qs'
import Cookies from 'js-cookie'
import Cache from '@/common/cache'
import store from '@/store'

let config = {
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // baseURL: '/api',
  timeout: process.env.VUE_APP_REQUSET_TIMEOUT, // 请求超时时间
  // withCredentials: false, // Check cross-site Access-Control
};

const _axios = Axios.create(config);

//请求拦截器
_axios.interceptors.request.use(
  function(config) { // 在发送请求之前做些什么
    let token = Cookies.get(Cache.TOKEN);
    if (token) config.headers['Authorization'] = "Bearer " + Cookies.get(Cache.TOKEN);
    let id = Cookies.get(Cache.LIANG_ID)
    //添加粮仓id
    if (id) config.headers['LiangId'] = id;
    return config;
  },
  function(error) { // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//响应拦截器
_axios.interceptors.response.use(
  function(response) { // 对响应数据做点什么
    return Promise.resolve(response.data)
  },
  function(error) { // 对响应错误做点什么
    return Promise.reject(error.response);
  }
);

//请求成功后判断状态码
function handleMsg(data, resolve, reject) {
  if (data.code === "200" || data.code === "0") {
    resolve(data)
  } else {
    verifyCode(data.code)
    reject(data);
  }
}

function verifyCode(code) {
  if (code === "40006" || code === "40007" || code === "40008") {
    store.dispatch('LogOut').then(() => {
      location.reload() // 为了重新实例化vue-router对象 避免bug
    })
  }
}

export default {
  //get请求
  Get: function(url, params) {
    return new Promise(function(resolve, reject) {
      _axios.get(url, {
        params: params
      }).then((res) => {
        handleMsg(res, resolve, reject)
      }).catch((err) => {
        if (err && err.data) verifyCode(err.data.code)
        reject(err)
      })
    })
  },
  //post请求
  Post: function(url, params, headers = {}) {
    return new Promise(function(resolve, reject) {
      _axios.post(url, Qs.stringify(params), {
        headers: headers
      }).then((res) => {
        handleMsg(res, resolve, reject)
      }).catch((err) => {
        if (err && err.data) verifyCode(err.data.code)
        reject(err)
      })
    })
  },
  //form表单提交请求
  // PostForm: function(url, data) {
  //   return new Promise(function(resolve, reject) {
  //     _axios.post(url, Qs.stringify(params), {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  //       }
  //     }).then((res) => {
  //       handleMsg(res, resolve, reject)
  //     }).catch((err) => {
  //       reject(err)
  //     })
  //   })
  // }
};
