module.exports = {
  //深克隆
  deepCopy: function(obj) {
    var result
    if (typeof obj === 'object') {
       result = obj.constructor === Array ? [] : {};

      for (var i in obj) {
        result[i] = typeof obj[i] === 'object' ? this.deepCopy(obj[i]) : obj[i];
      }
    } else {
       result = obj;
    }

    return result;
  },
  //浅克隆
  shallowClone: function(obj) {
    let cloneObj = {};

    for (let i in obj) {
      cloneObj[i] = obj[i];
    }

    return cloneObj;
  },
  // 判断是否为手机号
  isPoneAvailable: function(pone) {
    var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(pone)) {
      return false;
    } else {
      return true;
    }
  },
  //获取随机字母 n代表个数
  randomCoding: function(n) {
    //创建26个字母数组
    var arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
      'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    var idvalue = '';
    for (var i = 0; i < n; i++) {
      idvalue += arr[Math.floor(Math.random() * 26)];
    }
    return idvalue;
  },
  // 判断一个对象是不是空对象
  // Object.keys(obj).length === 0
}
