const cachePrefix = process.env.VUE_APP_NAME;
module.exports = {

  TOKEN_EXPIRES: 2, // 记住密码状态下的token在Cookie中存储的天数，默认2天

  PASS_EXPIRES: 7, //记住密码状态下的密码在Cookie中存储的天数，默认1天ss

  TOKEN: cachePrefix + "_token", //token

  ECHARTS_THEME: cachePrefix + "_echart_theme", //echart主题

  LIANG_ID: 'liang_id', //粮仓id

  LIANG_NAME: 'liang_name', //粮仓名称

  COLLAPSE_MENU: 'collapse_menu', //菜单收起缓存

  USER_NAME: 'user_name', //用户名

  USER_PASS: 'user_pass', //用户密码

  REMEMBER_ME: 'remember_me', //记住我

  WARE_CONFIG: 'ware_config', //平方仓配置
}
