import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


export const constantRouterMap = [{
    path: '/login',
    meta: {
      title: '登录'
    },
    component: (resolve) => require(['@/views/login'], resolve)
  },
  {
    path: '/grain/change',
    component: (resolve) => require(['@/views/grain/Change'], resolve),
    meta: {
      title: '粮库选择'
    }
  },
  {
    path: '/print/Pane',
    component: (resolve) => require(['@/views/print/Pane'], resolve),
    meta: {
      title: '平面打印'
    }
  },
  {
    path: '/print/Sum',
    component: (resolve) => require(['@/views/print/Sum'], resolve),
    meta: {
      title: '汇总打印'
    }
  },
  {
    path: '/print/Detail',
    component: (resolve) => require(['@/views/print/Detail'], resolve),
    meta: {
      title: '详情打印'
    }
  },
  {
    path: '/version/list',
    component: (resolve) => require(['@/views/version/List'], resolve),
    meta: {
      title: '详情打印'
    }
  },
  {
    path: '*',
    component: (resolve) => require(['@/views/error'], resolve),
    meta: {
      title: 'error'
    }
  }
]
export default new Router({
  mode: 'hash',
  // mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})
