import Vue from 'vue';
import App from './App.vue';
import router from '@/router/routers'
import global from '@/common/global'
import store from '@/store';
import ElementUI from 'element-ui';

import './router/index'

import '@/assets/styles/variables.scss'
import '@/assets/styles/common.css'
import '@/assets/iconfont/iconfont.css'

Vue.use(ElementUI, {
  size: 'mini'
});

Vue.use(global);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render(h) {
    return h(App);
  },
}).$mount('#app');
