import Axios from '@/api/axios'
import Api from '@/api/index'
import Lodash from 'lodash'
import Cookies from 'js-cookie'
import Cache from '@/common/cache'
import {
  verifyAuth
} from '@/common/jwt'
import Utils from '@/utils'


export default {
  install(Vue) {
    Vue.prototype.$axios = Axios;
    Vue.prototype.$lodash = Lodash
    Vue.prototype.$api = Api
    Vue.prototype.$util = Utils
    Vue.prototype.$cache = Cache
    Vue.prototype.$cookies = Cookies

    Vue.directive('auth', {
      bind: function(el, binding) {
        if (!verifyAuth(Cookies.get(Cache.TOKEN), binding.value)) {
          el.style.display = "none"
          let childNodes = el.childNodes
          childNodes.forEach(item => {
            el.removeChild(item)
          })
        }
      },
    })
  }
}
