 var rs = require('jsrsasign');

//解析token
export function decryptJwt(token) {
    if (token == 'null' || token == null || token == undefined) {
        return false;
    }
    var a = token.split(".");
    var uHeader = rs.b64utoutf8(a[0]);
    var uClaim = rs.b64utoutf8(a[1]);
    var sHeader = rs.KJUR.jws.JWS.readSafeJSONString(uHeader);
    var sClaim = rs.KJUR.jws.JWS.readSafeJSONString(uClaim);
    return {
        'sHeader': sHeader,
        'sClaim': sClaim
    }
}

//验证token
export function verifyAuth(token, auth) {
    var jwt = decryptJwt(token);
    if (jwt != null && jwt != undefined && jwt.sClaim != undefined && jwt.sClaim != null) {
        var authorities = jwt.sClaim.jurisdict_user;
        if (authorities == null || authorities == undefined) {
            return false;
        }
        return authorities.indexOf(auth) >= 0;
    } else {
        return false;
    }
}

//根据key值获取token里面的信息
export function getTokenInfo(token, key) {
    var sClaim = decryptJwt(token).sClaim;
    if (key != undefined && key != null) {
        return sClaim[key];
    }
    return sClaim;
}
