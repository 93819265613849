export default {
  //左菜单
  leftMenu: [{
    path: '/home',
    hide: true,
    name: "主页",
    meta: {
      title: '主页'
    },
    component: (resolve) => require(['@/views/home'], resolve),
  }, {
    path: '/user',
    hide: true,
    name: "基本资料",
    meta: {
      title: '基本资料',
      isBreadcrumb: true
    },
    component: (resolve) => require(['@/views/user/Index'], resolve),
  }, {
    path: '/pass',
    hide: true,
    name: "修改密码",
    meta: {
      title: '修改密码',
      isBreadcrumb: true
    },
    component: (resolve) => require(['@/views/user/Pass'], resolve),
  }],

  //菜单 无用
  menuList: [{
      path: '/temp',
      meta: {
        title: '测温记录',
        icon: 'el-icon- cyf_web_iconfont wendu',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/temp/Index'], resolve),
      children: [{
          path: '/temp/index',
          meta: {
            title: '数据汇总',
            isBreadcrumb: true
          },
          component: (resolve) => require(['@/views/temp/Index'], resolve)
        },
        {
          path: '/temp/pane',
          hide: true,
          meta: {
            noCache: true,
            title: '平面查看',
            isBreadcrumb: true
          },
          component: (resolve) => require(['@/views/temp/Pane'], resolve),
        },
        {
          path: '/temp/layer',
          hide: true,
          meta: {
            noCache: true,
            title: '单层查看',
            isBreadcrumb: true
          },
          component: (resolve) => require(['@/views/temp/Layer'], resolve),
        }
      ]
    },
    {
      path: '/analysis',
      meta: {
        noCache: true,
        title: '曲线分析',
        icon: 'el-icon- cyf_web_iconfont fenxi',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/analysis/Index'], resolve),
    },
    {
      path: '/solid',
      meta: {
        title: '立体查看',
        icon: 'el-icon- cyf_web_iconfont lifangtilitiduomiantifangkuai',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/solid/Ware'], resolve),
      children: [{
        path: '/solid/ware',
        meta: {
          title: '平方仓',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/solid/Ware'], resolve),
      }, {
        path: '/solid/silo',
        meta: {
          title: '立筒仓',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/solid/Silo'], resolve),
      }]
    },
    {
      path: '/abarn',
      meta: {
        title: '仓房管理',
        icon: 'el-icon- cyf_web_iconfont cangfangtubiao',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/abarn/Index'], resolve),
      children: [{
        path: '/abarn/param',
        meta: {
          title: '仓房参数',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/abarn/Param'], resolve),
      }, {
        path: '/abarn/record',
        meta: {
          title: '仓房档案',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/abarn/Index'], resolve),
      }, {
        path: '/abarn/mapping',
        meta: {
          title: '数据映射',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/abarn/Mapping'], resolve),
      }, {
        path: '/abarn/route',
        meta: {
          title: '无线路由',
          icon: '',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/abarn/Route'], resolve),
      }]
    }, {
      path: '/grain',
      meta: {
        title: '粮库管理',
        icon: 'el-icon- cyf_web_iconfont liangku',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/grain/Index'], resolve),
    },
    {
      path: '/system',
      meta: {
        title: '系统管理',
        icon: 'el-icon- cyf_web_iconfont shezhi',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/system/User'], resolve),
      children: [{
        path: '/system/user',
        meta: {
          title: '用户管理',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/User'], resolve),

      }, {
        path: '/system/userall',
        meta: {
          title: '用户管理',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/UserAll'], resolve),
      }, {
        path: '/system/depart',
        meta: {
          title: '公司管理',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/Depart'], resolve),
      }, {
        path: '/system/role',
        meta: {
          title: '角色管理',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/Role'], resolve),
      }, {
        path: '/system/menu',
        meta: {
          title: '菜单管理',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/Menu'], resolve),
      }, {
        path: '/system/branch',
        meta: {
          title: '粮库分配',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/Branch'], resolve),

      }, {
        path: '/system/handle',
        meta: {
          title: '系统操作',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/system/Handle'], resolve),
      }]
    }, {
      path: '/install',
      meta: {
        title: '操作设置',
        icon: 'el-icon- cyf_web_iconfont caozuo',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/install/Index'], resolve)
    },
    {
      path: '/version',
      meta: {
        title: '版本管理',
        icon: 'el-icon- cyf_web_iconfont banben',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/version/Index'], resolve)
    },
    {
      path: '/logger',
      meta: {
        title: '日志管理',
        icon: 'el-icon- cyf_web_iconfont rizhi',
        isBreadcrumb: true
      },
      component: (resolve) => require(['@/views/logger/Renew'], resolve),
      children: [{
        path: '/logger/renew',
        meta: {
          noCache: true,
          title: '续订记录',
          isBreadcrumb: true
        },
        component: (resolve) => require(['@/views/logger/Renew'], resolve),
      }]
    }
  ]
}
